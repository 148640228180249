<!-- 保司管理测试编辑 -->
<template>
  <el-dialog
    width="30%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    top="5vh"
    custom-class="ele-dialog-form"
    :title="isUpdate?'测试':'测试'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="120px">
      <el-form-item label="测试访问账号" prop="test_ak_account">
        <el-input clearable style="" v-model="form.test_ak_account" placeholder="请输入测试访问账号"></el-input>
      </el-form-item>
      <el-form-item label="测试访问秘钥" prop="test_ak_secret">
        <el-input clearable style="" v-model="form.test_ak_secret" placeholder="请输入测试访问秘钥"></el-input>
      </el-form-item>
      <el-form-item label="测试地址" prop="test_api_url">
        <el-input clearable style="" v-model="form.test_api_url" placeholder="请输入测试地址"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>


<script>
// 引入的接口
import {
  get_insurer_Info, update_insurer_Info,
} from '@/api/system'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 验证规则
      rules: {
        test_ak_account: [
          { required: true, message: '请输入测试访问账号', trigger: 'blur' },
          { min: 0, max: 128, message: '长度最多128个字符', trigger: 'blur' }
        ],
        test_ak_secret: [
          { required: true, message: '请输入测试访问秘钥', trigger: 'blur' },
          { min: 0, max: 128, message: '长度最多128个字符', trigger: 'blur' }
        ],
        test_api_url: [
          { required: true, message: '请输入测试地址', trigger: 'blur' },
          { min: 0, max: 1000, message: '长度最多1000个字符', trigger: 'blur' }
        ]
      },
    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = this.data;
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
    // 获取详情
    this.get_get_insurer_Info();
  },

  methods: {
    //获取详情
    get_get_insurer_Info(){
      get_insurer_Info({id: this.data.id}).then(res => {
        console.log(res);
        if(res.code == 200){
          this.form = res.data;
        }else {
          this.$message.error(res.msg);
        }
      })
    },
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            type: 'test',
            insurer_id: this.data.id,
            ak_account: this.form.test_ak_account,
            ak_secret: this.form.test_ak_secret,
            api_url: this.form.test_api_url
          }
          update_insurer_Info(data).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg)
              this.updateVisible(false);
              this.$emit('done');
            }else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
